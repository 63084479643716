
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import ApiMember from "@/request/apis/api_member";
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import apiMember from "@/request/apis/api_member";
    import ApiUser from "@/request/apis/api_user";
    interface IState {
        list:any,
        searches:any,
        total:any,
        visible:any,
        title:any,
        formState:any,
        type:any,
        levelData:any,
        artData:any,
        goods_name:'',
        page3:any,
        goodList:any,
        total3:any,
        coupleName:any,
        coupleList:any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list:[],
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                    name:''
                },
                total:0,
                levelData:[],
                artData:[],
                title:'',
                goods_name:'',
                page3:1,
                goodList:[],
                total3:0,
                visible:false,
                formState:{
                    id:'',
                    name:'',
                    user_level_id:undefined,
                    type:undefined,
                    goods_id:undefined,
                    count:'',
                    coupon_id:undefined,
                    article_id:undefined,
                },
                type:'',
                coupleName:'',
                coupleList:[]
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '类型',
                    dataIndex: 'type_id',
                    key: 'type_id',
                    align:'center'
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    align:'center'
                },
                {
                    title: '内容',
                    dataIndex: 'content',
                    key: 'content',
                    align:'center'
                },
                {
                    title: '修改时间',
                    key: 'update_time',
                    dataIndex: 'update_time',
                    align:'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150
                },
            ];

 

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async()=>{
                const params = {...state.searches}
                const res = await ApiMember.tipsList(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.list = data.list
                    state.total = data.total
                }else{
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };


            const addApply = () =>{
                state.title = "新增公告提示"
                state.type = 1
                state.formState={
                    type_id:undefined,
                    status:'',
                    content:''
                },
                    state.visible = true
            }
            const edit = (val) =>{
                state.title = "编辑公告提示"
                state.type = 2
                state.formState={
                    id:val.id,
                    content:val.content,
                    type_id:val.type_id,
                    status:val.status,
                }
                state.visible = true
            }
            const formRef = ref();
           

            const onSubmit = async () => {
                formRef.value.validate() .then(async() => {
                    if (state.type == 1) {
                        const params = {...state.formState}
                        const res = await ApiMember.tipsEdit(params)
                        if(res.error_code == 0){
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    } else {
                        const params = {...state.formState}
                        const res = await ApiMember.tipsEdit(params)
                        if(res.error_code == 0){
                            message.success(res.msg)
                            state.visible = false
                            getList()
                        }else{
                            message.error(res.msg)
                        }
                    }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }

            const onDel = async (item,item2) => {
                const params = {type_id:item.type_id,status:item2}
                const res = await apiMember.tipsStatus(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }
            const onSearch = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }

           

            
            const onReset = () => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                state.searches.name = ''
                getList()
            }

    
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                onSearch,           
                onReset,
                pagination,
                columns,
                edit,
                onDel,
                formRef,
                onSubmit,
                addApply,
                handleTableChange
            }
        }
    })
